$oswald: 'Oswald', sans-serif;
$open: 'Open Sans', sans-serif;
$lato: 'Lato', sans-serif; // COLORS //
$base: #545454; //rgba(84,84,84,1);
$baseDarker: #4E4E56;
$mastBG: #545454;
$red: #E81E25; //rgba(232,30,37,1);
$pink: #ff000012;
$baseBg: #F4F4F6; // TIMING, MISC //
$radio: #4E4E56;
$lightBorder: #d8d8d8;
$highlight-gray: #F8F8F8;
$validateGreen: #02a018;
$transition-timing: 0.3s; // QUERIES //
$sixHundo: (
    media: 600px,
);
$tablet: (
    media: 678px,
);
$wrapperHundo: (
    media: 1200px,
);