body,
html {
    color: $base;
    font: 16px/1.3em $open;
}

a {
    text-decoration: none;
    color: $red;
    transition: all $transition-timing ease-in-out;
    font-weight: 600;

    &:hover {
        color: rgba($red, .7);
    }
}

h1 {
    font-size: 28px;
    color: $red;
    text-transform: uppercase;
    font-family: $oswald;
    font-weight: 400;
    line-height: 1.1em;

    &.beta {
        margin: 5px 0 0 !important;
    }
}

h1.beta + p {
    margin-top: 10px;
}
@include grid-media($sixHundo) {
    h1 {
        font-size: 42px;
    }
}

p {
    line-height: 1.5em;
}

.intro-title {
    text-transform: uppercase;
    font-family: $oswald;
    font-weight: 500;
}

.alpha {
    margin-bottom: 0;
}

.beta {
    margin-top: 16px;
}

ol {
    padding-left: 20px;

    li {
        margin: 6px 0;
    }
}

.wrapper {
    max-width: 1200px;
    width: 95%;
    margin: 0 auto;
    clear: both;
}

#content {
    overflow: hidden;
}

.relative {
    position: relative;
}

.center {
    text-align: center;
}

.clear-left {
    clear: left;
}

.float-right {
    float: right;
}

.pointer {
    cursor: pointer;
}

.base {
    color: $base;
}

small {
    font-size: 12px;
}

.half {
    width: calc(50% - 5px);
    display: inline-block;
}

.right-align {
    text-align: right;
}

.underline {
    position: relative;

    span:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: currentColor;
        transition: background-color $transition-timing ease-in-out;
    }

    &:hover {
        span:after {
            background-color: $red;
        }
    }
}

.screen-reader {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}