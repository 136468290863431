$inputSidePadding: 12px;

.error {
    color: $red;
    font-style: italic;

    &.highlight {
        background: $pink;
        padding: 8px;
        border-radius: 4px;

        a {
            font-weight: 400;
        }
    }

    .fa,
    .fal {
        padding-left: 5px;
    }
}

label {
    font: 14px/1em $open;
    color: $baseDarker; //    letter-spacing: 0.5px;

    &.error {
        color: $red;
        font-style: italic;
    }

    &.for-radio {
        top: -4px;
        position: relative;

        &:not(.last) {
            margin-right: 8px;
        }
    }
}

[type=checkbox],
[type=email],
[type=password],
[type=radio],
[type=tel],
[type=text],
textarea {
    color: $base;
    background: #fff;
    border: none;
    border-radius: 1px;
    padding: 10px $inputSidePadding;
    font-family: $open;
    //letter-spacing: 0.5px;
    &.hide {
        display: none;
        visibility: hidden;
    }

    &.pad-bottom {
        margin-bottom: 20px;
        padding: 14px 15px 15px;
        border: 1px solid #4e4e56;
        height: auto;
        border-radius: 3px;
    }
}

textarea {
    margin-bottom: 20px;
    padding: 14px 15px 15px;
    border: 1px solid #4e4e56;
    height: auto;
    border-radius: 3px;
}

[type=email],
[type=password],
[type=tel],
[type=text] {
    height: 39px;
}

[type=email],
[type=password],
[type=tel],
[type=text],
textarea {
    &:active,
    &:focus,
    &:hover {
        box-shadow: 0 2px 4px 0 rgba(78,78,86,.1);
    }
}

[type=radio] {
    width: 16px;
    height: 16px;
    border: 1px solid $radio;
    transition: all $transition-timing ease-in-out;

    &:hover {
        box-shadow: inset 0 0 1px 4px #fff;
        background-color: $base;
    }

    &:checked {
        background-color: #fff;
        border: 6px solid $red;
    }
}

input[type="checkbox"] {
    width: 15px;
    height: 15px;
    padding: 0;
    margin-right: 7px;
    position: relative;
    top: 3px;

    &:not(.hidden) {
        border: 1px solid $baseDarker;

        &:hover {
            box-shadow: inset 0 0 0 3px #fff;
            background-color: rgba($base, .4);
        }

        &:checked {
            box-shadow: inset 0 0 0 2px #fff;
            background-color: $red;
        }
    }
}

.position-end {
    position: absolute;
    right: 0;
    top: 0;
}

#togglePassword,
#togglePasswordConfirm {
    background: transparent;
    width: 5px;
    padding: 12px 0;
}

#errors,
#password-info-list,
#phone-info-list {
    list-style: none;
    padding-left: 0;
    font-size: 11px;

    li {
        line-height: 1.3em;
    }

    .fal {
        width: 12px;
    }

    .invalid {
        color: rgba($base, .5);

        .fa-check {
            display: none;
        }
    }

    .valid {
        color: $validateGreen;

        .fa-times {
            display: none;
        }
    }
}

form {
    h3 {
        margin: 10px 0;
    }
}
/* Change Autocomplete styles in Chrome*/
input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
    font-size: 16px;
    border: none;
    background-color: rgb(232, 240, 254) !important;
    -webkit-text-fill-color: $base !important;
    color: $base !important;
    -webkit-box-shadow: 0 2px 4px 0 rgba(78,78,86,.1);
    transition: background-color $transition-timing ease-in-out 0s;
}

input[type="submit"] {
    color: #fff;
    background-color: $red;
    border: none;
    border-radius: 30px;
    padding: 17px 35px;
    cursor: pointer;
    transition: background-color $transition-timing ease-in-out;

    &.disabled,
    &:disabled,
    &[disabled] {
        background-color: rgba(84,84,84,.6);
    }

    &:hover:not(disabled):not([disabled]):not(.disabled) {
        background-color: lighten($red, 10%);
    }
}

a.button {
    color: #fff;
    background-color: $red;
    border: none;
    border-radius: 30px;
    padding: 17px 35px;
    cursor: pointer;
    transition: background-color $transition-timing ease-in-out;
    display: block;
    float: left;

    &:hover {
        background-color: lighten($red, 10%);
    }
}

.clear-left {
    clear: left;
}

.large,
.medium,
.small {
    overflow: hidden;
    @include grid-container;

    > div {
        width: 100%;
        margin-bottom: 20px;

        input:not([type="radio"]):not([type="checkbox"]),
        textarea {
            //width: calc(100% - #{$inputSidePadding}*2);
            width: 100%;
        }

        &.childrenHalf {
            @media all and (min-width:400px) {
                > div {
                    width: calc(50% - 5px);
                    display: inline-block;

                    &:last-of-type {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}

.medium {
    @include grid-media($wrapperHundo) {
        @include grid-collapse;

        > div {
            @include grid-column(6);
        }
    }
}

.ui-autocomplete.ui-front {
    list-style: none;
    padding: 4px 8px;
    background: #fff;
    width: 96%;
    max-width: 500px;
    border-radius: 0 1px 1px;
    margin-top: 17px;
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;

    li {
        padding: 14px 12px;
        margin: 0;
        font: 16px/1.1em $open;

        &:hover {
            background: $highlight-gray;
            cursor: pointer;
            font-weight: 600;
        }
    }
}

#manual {
    display: block;
}
// https://tympanus.net/Tutorials/CustomFileInputs/

.js .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    max-width: none;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 16px 10px;
    width: 100%;
    text-align: center;
}

.no-js .inputfile + label {
    display: none;
}

.inputfile.has-focus + label,
.inputfile:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
    /* pointer-events: none; */
    /* in case of FastClick lib use */
}

#file-input .fal {
    color: $red;
}
/* style 2 */
.inputfile + label {
    color: $baseDarker;
    border-radius: 4px;
    border: 1px solid currentColor;

    &:focus,
    &:focus-within,
    &:hover,
    &:visited {
        box-shadow: 0 2px 4px 0 rgba(78,78,86,.1);
        outline: none;
    }
}

.inputfile + label:hover,
.inputfile.has-focus + label,
.inputfile:focus + label {
    color: $baseDarker;
    box-shadow: 0 2px 4px 0 rgba(78,78,86,.1);
    outline: none;
}

.add_row {
    position: relative;

    .inputfile+label {
        border-radius: 0;
        border-color: transparent;
        border-bottom-color: $lightBorder;
        transition: all $transition-timing ease-in-out;

        &:hover {
            border: 1px solid $baseDarker;
            border-radius: 4px;
        }
    }

    .delete {
        display: none;
        position: absolute;
        right: 0;
        background: transparent;
        border: none;
        top: 10px;
        margin: 0;
        padding: 0;

        .fal {
            transform: rotate(45deg);
        }
    }

    .inputfile+label.file-attached+.delete {
        display: block;

        .fal {
            color: $base;
            opacity: 0.6;
        }

        &:hover {
            .fal {
                opacity: 1;
            }
        }
    }

    .inputfile+label.file-attached .fal {
        display: none;
    }

    &#first-row {
        display: none;
    }
}

.box-file-upload.in-play {
    .add_row#first-row {
        display: block;
    }
}

.inputfile+label.file-attached {
    font-size: 14px;
    text-align: left;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $lightBorder;
    padding-left: 0;
    position: relative;

    .fal {
        position: absolute;
        right: 0;
        color: $base;
        opacity: 0.6;
        transform: rotate(45deg);
    }

    &:focus,
    &:focus-within,
    &:hover,
    &:visited {
        box-shadow: none;

        .fal {
            color: $red;
            opacity: 1;
        }
    }
}

.inputfile + label.file-attached:hover,
.inputfile.has-focus + label.file-attached,
.inputfile:focus + label.file-attached {
    box-shadow: none;

    .fal {
        color: $red;
        opacity: 1;
    }
}

.box-file-upload {
    .fal {
        color: $red;
        padding-left: 8px;
    }
}

.add-more .inputfile+label,
button#add {
    width: 100%;
    text-align: center;
    background: transparent;
    color: $baseDarker;
    border: none;
    padding: 20px 0;
    border: 1px solid transparent;
    transition: all $transition-timing ease-in-out;

    .fal {
        padding-left: 8px;
        color: $red;
    }

    &:focus,
    &:focus-within,
    &:hover,
    &:visited {
        border-color: $baseDarker;
    }
}

#add {
    display: none;
}

#companyInput {
    border-bottom: 1px solid $baseBg;
}

#title.hide {
    display: none;
    visibility: hidden;
}

#errors {
    &.hide {
        display: none;
        visibility: hidden;
    }

    li {
        &.hide {
            display: none;
            visibility: hidden;
        }
    }
}

.filterGroup {
    position: relative;

    label {
        display: block;
        margin: 8px 0;
    }

    select {
        display: block;
        font-size: 16px;
        font-family: $open, sans-serif;
        //font-weight: 700;
        color: $baseDarker;
        line-height: 1.3;
        padding: 14px 15px 15px;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        margin: 0 0 15px;
        border: 1px solid $baseDarker;
        box-shadow: none;
        border-radius: 4px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        //background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
        background-image: none;
        background-repeat: no-repeat, repeat;
        background-position: right 0.7em top 50%, 0 0;
        background-size: 0.65em auto, 100%;
        /*
        &:after,
        &:before {
            position: absolute;
            content: "";
            border-top: 9px solid #fafafa;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            right: 30px;
            bottom: 28px;
        }

        &:before {
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
            border-top: 9px solid #4e4e56;
            bottom: 27px;
        }
        */
        &::-ms-expand {
            display: none;
        }

        &:hover {
            border-color: #888;
        }

        &:focus {
            border-color: gba(105, 178, 255, 0.7);
            box-shadow: 0 0 3px 0 rgba(105, 178, 255, 0.7);
            color: #222;
            outline: none;
        }

        option {
            font-weight: normal;
        }
    }

    &:after {
        content: '<>';
        font: 17px "Consolas", monospace;
        color: $baseDarker;
        transform: rotate(90deg) translateY(-50%);
        right: 25px;
        /*Adjust for position however you want*/
        top: 50%;
        padding: 0 0 2px;
        border-bottom: 1px solid $baseDarker;
        /*left line */
        position: absolute;
        pointer-events: none;
    }
}
// scrollbars

.ui-autocomplete::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

.ui-autocomplete::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $red;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}