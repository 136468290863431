.bg-gray {
    padding: 25px 0;

    #primary,
    #secondary {
        background-color: $baseBg;
        padding: 70px 15px;
        @media all and (min-width:500px) {
            padding: 70px 40px;
        }
    }

    #secondary {
        margin-top: 35px;
    }
    @include grid-media($tablet) {
        display: flex;
        padding: 70px 0;

        #primary {
            @include grid-column(7);
        }

        #secondary {
            margin-top: 0;
            @include grid-column(5);
        }
    }
    @include grid-media($wrapperHundo) {
        #primary {
            @include grid-column(8);
        }

        #secondary {
            @include grid-column(4);
        }
    }
}