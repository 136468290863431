#masthead {
    background-color: $mastBG;
    padding: 30px 0;
}

#mast-logo {
    clear: both;
    display: inline-block;

    img {
        width: 100%;
        max-width: 600px;
    }
    @include grid-media($tablet) {
    }
}

#logout {
    float: right;
    color: #fff;
    font-size: 13px;
    margin: 0px 0 15px;
    @include grid-media($wrapperHundo){
        margin-top: 24px;
    }
    &:hover {
        text-decoration: underline;
    }
}

#hero {
    background-size: cover !important;
    padding: 70px 0;

    h1 {
        color: #fff;
        font-size: 70px;
        text-transform: uppercase;
        text-shadow: 0 0 0 rgba(0,0,0,.25);
        @include grid-media($wrapperHundo) {
            font-size: 100px;
        }
    }
}