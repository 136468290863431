#colophon {
    background-color: $mastBG;
    padding: 30px 0;
    color: #fff;

    a {
        color: #fff;
        text-decoration: underline;
    }

    .footer-pipe{
        display: inline; 
    }
    p{
        display: inline;
    }

    .copyright{
        display: inline;
    }
    @media all and (max-width:605px) {
        .footer-pipe{
            display: none;
        }
        .right-align,
        a {
            display: block;
        }
        .copyright{
            display: flex;
        }

        .right-align {
            text-align: left;
            float: none;

            a {
                display: inline;
            }
        }
    }
}