.media {
    &-medium {
        max-width: 180px;
        height: auto;
        width: 100%;
    }
}

#trusted-logos {
    img {
        margin: 20px auto;
    }
}

progress[value] {
    width: 100%;
    height: 10px;
}

progress {
    appearance: none;
}

::-webkit-progress-bar {
    background-color: $baseBg;
}

progress:not([value]) {
    background-color: #ccc;
    width: 100%;
    height: 0;
}

progress::-webkit-progress-bar {
    //background-color: $red;
}

progress::-webkit-progress-value {
    //background-color: $red;
}

progress::-moz-progress-bar {
    //background-color: $red;
}

progress[value] {
    background-image: linear-gradient(-45deg, transparent 33%, rgba(0, 0, 0, .1) 33%, rgba(0,0, 0, .1) 66%, transparent 66%), linear-gradient(to top, rgba(255, 255, 255, .25), rgba(0, 0, 0, .25)), linear-gradient(to right, #e81e25, #e81e25);
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}

progress[value]::-webkit-progress-value {
    background-image: -webkit-linear-gradient(-45deg, transparent 33%, rgba(0, 0, 0, .1) 33%, rgba(0,0, 0, .1) 66%, transparent 66%), -webkit-linear-gradient(to top, rgba(255, 255, 255, .25), rgba(0, 0, 0, .25)), -webkit-linear-gradient(to right, #e81e25, #e81e25);
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}
@-webkit-keyframes animate-stripes {
    100% {
        background-position: -100px 0;
    }
}
@keyframes animate-stripes {
    100% {
        background-position: -100px 0;
    }
}

#progress-details {
    em,
    h3 {
        display: inline-block;
        width: 50%;
    }

    em {
        text-align: right;
        font-size: 14px;
        width: calc(50% - 10px);
    }
}